export const mainConfig = {
  mainTheme : "basic",
  firebaseConfig : {
    apiKey: "AIzaSyCR0I3uV1AvChz-iMBSQMjhLJtGqpUC6bU",
    authDomain: "hbs-personal.firebaseapp.com",
    databaseURL: "https://hbs-personal.firebaseio.com",
    projectId: "hbs-personal",
    storageBucket: "hbs-personal.appspot.com",
    messagingSenderId: "454239726753",
    appId: "1:454239726753:web:8c4b0ea94b08f815a514e0",
    measurementId: "G-35NXTCMPLQ"
  },
  pagination : {
    pageSize : 2
  }
}